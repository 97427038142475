/* eslint-disable jsx-a11y/click-events-have-key-events */
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { Modal } from "react-bootstrap"

import { Video } from "../../../UI/Common"
import closeIcon from "../assets/modal-video-close.svg"

import PaddingWrapper from "./PaddingWrapper"
import {
  videoImageCls,
  playIconCls,
  modalContentCls,
  modalCloseBtnCls,
} from "./VideoContent.module.scss"

const VideoContent = ({ videoTitle, videoUrl, videoPreviewImage, config }) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <PaddingWrapper padding={config?.padding}>
        {videoPreviewImage ? (
          <span
            className={`${videoImageCls} ${playIconCls}`}
            onClick={handleShow}
            role="button"
            tabIndex={0}>
            <GatsbyImage
              className="w-100 h-100"
              image={getImage(videoPreviewImage?.localFile)}
              alt={videoPreviewImage?.altText}
            />
          </span>
        ) : (
          <Video
            src={videoUrl}
            title={videoTitle}
            frameBorder={0}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          />
        )}
      </PaddingWrapper>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        fullscreen={'true'}
        contentClassName={modalContentCls}>
        <Modal.Body>
          <div className="position-relative h-100 w-100">
            <a
              href="#"
              className={modalCloseBtnCls}
              onClick={handleClose}
              role="button"
              tabIndex={0}>
              <img src={closeIcon} alt="close" />
            </a>
            <div className="d-flex flex-column justify-content-center align-items-center h-100 w-100">
              <div>
                <Video
                  src={videoUrl}
                  title={videoTitle}
                  frameBorder={0}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default VideoContent
