// extracted by mini-css-extract-plugin
export var bgGrey100 = "VideoContent-module--bg-grey-100--6fae5";
export var bgGrey150 = "VideoContent-module--bg-grey-150--e421f";
export var bgGrey200 = "VideoContent-module--bg-grey-200--121d8";
export var bgGrey300 = "VideoContent-module--bg-grey-300--edd7e";
export var bgGrey400 = "VideoContent-module--bg-grey-400--e00eb";
export var bgGrey500 = "VideoContent-module--bg-grey-500--537a7";
export var bgGrey600 = "VideoContent-module--bg-grey-600--d4754";
export var bgGrey700 = "VideoContent-module--bg-grey-700--bbbf0";
export var bgGrey800 = "VideoContent-module--bg-grey-800--be456";
export var bgGrey900 = "VideoContent-module--bg-grey-900--3b26d";
export var modalCloseBtnCls = "VideoContent-module--modalCloseBtnCls--55a4b";
export var modalContentCls = "VideoContent-module--modalContentCls--d5d7e";
export var playIconCls = "VideoContent-module--playIconCls--811ed";
export var textGrey100 = "VideoContent-module--text-grey-100--7cf8b";
export var textGrey150 = "VideoContent-module--text-grey-150--86c24";
export var textGrey200 = "VideoContent-module--text-grey-200--f356d";
export var textGrey300 = "VideoContent-module--text-grey-300--88c18";
export var textGrey400 = "VideoContent-module--text-grey-400--388ea";
export var textGrey500 = "VideoContent-module--text-grey-500--3ffad";
export var textGrey600 = "VideoContent-module--text-grey-600--2d4e6";
export var textGrey700 = "VideoContent-module--text-grey-700--74679";
export var textGrey800 = "VideoContent-module--text-grey-800--e6ed5";
export var textGrey900 = "VideoContent-module--text-grey-900--6f7a1";
export var videoImageCls = "VideoContent-module--videoImageCls--d5c61";